import React, { useEffect, useRef } from 'react';
import TRUNK from 'vanta/dist/vanta.trunk.min';
import p5 from 'p5';
import resume from './../../Assets/Gangey_Patel_Resume.pdf';
import { SiChessdotcom, SiGithub, SiInstagram, SiLeetcode, SiLinkedin, SiSpotify, SiTwitter } from 'react-icons/si';

const socialLinks = [
  { Icon: SiLinkedin, url: "https://www.linkedin.com/in/gangeyp", label: "LinkedIn" },
  { Icon: SiGithub, url: "https://github.com/gangeypatel", label: "GitHub" },
  { Icon: SiTwitter, url: "https://twitter.com/GangeyP", label: "Twitter" },
  { Icon: SiInstagram, url: "https://instagram.com/akabheeshma", label: "Instagram" },
  { Icon: SiChessdotcom, url: "https://link.chess.com/friend/BJJEa7", label: "Chess.com" },
  { Icon: SiSpotify, url: "https://open.spotify.com/user/31w32a4d5jo2w2y53qvioyezczsi", label: "Spotify" },
  { Icon: SiLeetcode, url: "https://leetcode.com/GangeyP", label: "LeetCode" }
];

const Hero = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect;
    if (vantaRef.current) {
      vantaEffect = TRUNK({
        el: vantaRef.current,
        p5: p5,
        mouseControls: false,
        touchControls: false,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        spacing: 7.00,
        chaos: 9.00,
        backgroundColor: 0xe6ebe0,
        color: 0xF1AE8B,
        opacity: 0.1
      });
    }
    return () => vantaEffect?.destroy();
  }, []);

  return (
    <div ref={vantaRef} className="min-h-screen flex relative">
      <div className="absolute top-40 md:top-20 right-0 px-4 md:px-10 lg:px-20 text-[#1b4965]">
        <div className="intro-section">
          <h1 className="text-3xl sm:text-5xl lg:text-7xl xl:text-9xl mb-2">
            Hello, I am <span className="text-[#ED6A5A]">Gangey Patel</span> aka GG.
          </h1>
          <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-4">
            A friendly and passionate software engineer, ready to craft innovation.
          </h2>
        
          <div className="flex flex-wrap gap-4 mb-6 md:hidden">
            {socialLinks.map(({ Icon, url, label }) => (
              <a 
                key={label}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl hover:text-[#ED6A5A] transition-colors"
                aria-label={label}
              >
                <Icon color="#0b3954" size="1rem" />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="absolute bottom-10 md:bottom-20 right-0 px-4 md:px-10 lg:px-20 text-[#1b4965]">
        <p className="text-sm sm:text-base md:text-lg lg:text-xl mb-4 max-w-xl leading-relaxed">
          I am a highly motivated and results-oriented software developer with a Master's degree in Computer Science from Arizona State University. I am eager to leverage my skills and knowledge to contribute to a fast-paced and innovative software engineering team. I am a quick learner and a team player with a strong work ethic.
        </p>
        <div className="flex flex-wrap gap-4">
          <button
            onClick={() => window.location.href = "mailto:gangey.apatel@gmail.com"}
            className="btn bg-[#1b4965] text-[#e6ebe0] hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#1b4965] transition duration-200 rounded-lg px-4 py-2"
          >
            Hire me!
          </button>
          <button
            onClick={() => window.open(resume, '_blank')}
            className="btn bg-[#1b4965] text-[#e6ebe0] hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#1b4965] transition duration-200 rounded-lg px-4 py-2"
          >
            Resume
          </button>
        </div>
      </div>

      {/* Social Icons for larger screens */}
      <div className="hidden md:flex absolute bottom-10 left-10 px-4 md:px-10 gap-4 text-[#1b4965]">
        {socialLinks.map(({ Icon, url, label }) => (
          <a 
            key={label}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl hover:text-[#ED6A5A] transition-colors"
            aria-label={label}
          >
            <Icon color="#0b3954" size="1rem" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Hero;