import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Oops from './Components/Oops';
import About from './Pages/About';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Resume from './Pages/Resume';
import NavBar from './Components/NavBar';
import ScrollToTop from './ScrollToTop';

const App = () => {
  return (
    <main>
      <Router>
        <NavBar/>
        <ScrollToTop/>
        <div>
            <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="/projects" element={<Home/>} />
                  <Route path="/resume" element={<Home/>} />
                  <Route path="/about" element={<Home/>} />
                  <Route path="/Oops" element={<Oops/>} />
            </Routes>
      </div>
      </Router>
    </main>
  )
}

export default App